var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShow),expression:"shouldShow"}],staticClass:"ui-form-field",class:_vm.customClass},[_c('div',{staticClass:"ui-form-field__label"},[(_vm.data.label)?_c('div',{staticClass:"text",domProps:{"textContent":_vm._s(_vm.data.label)}}):_vm._e(),_vm._v(" "),(_vm.data.isRequired)?_c('div',{staticClass:"required"},[_vm._v("*")]):_vm._e()]),_vm._v(" "),_c('validation-provider',{attrs:{"vid":'validate_' + _vm.fieldId,"rules":_vm.rules,"customMessages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"ui-form-field__input-wrapper checkbox",class:_vm.data.size},[_vm._l((_vm.data.choices),function(choice,index){return _c('div',{key:index,staticClass:"checkbox-wrapper"},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCheckbox(choice)),expression:"showCheckbox(choice)"},{name:"model",rawName:"v-model",value:(_vm.checkedVals),expression:"checkedVals"}],staticClass:"ui-form-field__checkbox",attrs:{"id":'form_id__' +
              _vm.data.fieldName +
              '_field_id__' +
              choice.id +
              '_value__' +
              choice.value,"disabled":choice.disabled,"type":"checkbox"},domProps:{"value":choice.value,"checked":Array.isArray(_vm.checkedVals)?_vm._i(_vm.checkedVals,choice.value)>-1:(_vm.checkedVals)},on:{"change":[function($event){var $$a=_vm.checkedVals,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=choice.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkedVals=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkedVals=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkedVals=$$c}},_vm.detailsUpdated]}}),_vm._v(" "),_c('label',{staticClass:"ui-form-field__checkbox-label",attrs:{"for":'form_id__' +
              _vm.data.fieldName +
              '_field_id__' +
              choice.id +
              '_value__' +
              choice.value}},[_vm._v("\n          "+_vm._s(choice.text)+"\n        ")]),_vm._v(" "),_c('padlock-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLock(choice)),expression:"showLock(choice)"}],staticClass:"checkbox__padlock"})],1)}),_vm._v(" "),(_vm.charLimit)?_c('div',{staticClass:"ui-form-field__char-limit",domProps:{"textContent":_vm._s(_vm.charLimit)}}):_vm._e(),_vm._v(" "),(_vm.data.description)?_c('div',{staticClass:"ui-form-field__instruction",class:{
          top: _vm.data.descriptionPlacement === 'top',
          bottom: _vm.data.descriptionPlacement === 'bottom',
        }},[_c('span',{domProps:{"textContent":_vm._s(_vm.data.description)}})]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"ui-form-field__error"},[_vm._v(_vm._s(errors[0]))])],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }