//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormMixins from '~/mixins/form';
import CalculateConditionalLogicMixins from '~/mixins/calculateConditionalLogic';
import PadlockIcon from '~/static/svg/padlock.svg';

export default {
  mixins: [FormMixins, CalculateConditionalLogicMixins],
  components: {
    PadlockIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    externalUpdatedValue: {
      required: false,
      type: String,
    },
  },
  watch: {
    externalUpdatedValue(value) {
      this.input = value;
      this.checkedVals = this.input.split(',');
    },
  },
  data() {
    return {
      checkedVals: [],
    };
  },
  computed: {
    rules() {
      if (this.data.isRequired) {
        return {
          required: {
            allowFalse: false,
          },
        };
      }
    },
  },
  methods: {
    detailsUpdated(errors) {
      setTimeout(() => {
        this.input = this.checkedVals.join(',');

        this.$emit('detailsUpdated', {
          fieldName: this.data.fieldName,
          value: this.input,
          errors: errors,
        });
      }, 0);
    },
    showCheckbox(choice) {
      if (choice.isLocked === true) {
        // return false;
      }

      return true;
    },
    showLock(choice) {
      if (choice.isLocked === true) {
        return true;
      }

      return false;
    },
  },
  mounted() {
    if (this.input) {
      this.checkedVals = this.input.split(',');

      const validChoices = [];

      for (const val of this.checkedVals) {
        const valid = this.data.choices.find(choice => {
          return choice.value === val;
        });

        if (valid) {
          validChoices.push(val);
        }
      }

      this.checkedVals = validChoices;
      this.input = this.checkedVals.join(',');
    }
  },
};
