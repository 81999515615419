//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { debounce } from '~/util/helper';

import FormMixins from '~/mixins/form';
import CalculateConditionalLogicMixins from '~/mixins/calculateConditionalLogic';

export default {
  mixins: [FormMixins, CalculateConditionalLogicMixins],
  props: {
    sub: {
      default: false,
      type: Boolean,
    },
    resetInput: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    delayed() {
      return debounce(this.delayUpdated, 250);
    },
  },
  watch: {
    'data.defaultValue'(value) {
      this.input = value;
    },
  },
  methods: {
    inputUpdated(errors) {
      this.$emit('inputUpdated', {
        fieldName: this.data.fieldName,
        value: this.input,
        errors: errors,
      });
    },
    detailsUpdated(errors) {
      this.$emit('detailsUpdated', {
        fieldName: this.data.fieldName,
        value: this.input,
        errors: errors,
      });
    },
    focusUpdated(errors) {
      this.$emit('focusUpdated', {
        fieldName: this.data.fieldName,
        value: this.input,
        errors: errors,
      });
    },
    delayUpdated(errors) {
      this.$emit('delayUpdated', {
        fieldName: this.data.fieldName,
        value: this.input,
        errors: errors,
      });
    },
  },
  mounted() {
    if (this.data.defaultValue) {
      this.input = this.data.defaultValue;
      this.$emit('inputUpdated', {
        fieldName: this.data.fieldName,
        value: this.input,
        errors: this.errors,
      });
    } else {
      this.input = '';
    }

    if (this.resetInput) {
      this.input = '';
    }
  },
};
