//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormMixins from '~/mixins/form';
import CalculateConditionalLogicMixins from '~/mixins/calculateConditionalLogic';

export default {
  mixins: [FormMixins, CalculateConditionalLogicMixins],
  beforeMount() {
    this.data.choices.forEach(choice => {
      if (choice.isSelected) {
        this.input = choice.value;
        return;
      }
    });
  },
  methods: {
    detailsUpdated(errors) {
      setTimeout(() => {
        this.$emit('detailsUpdated', {
          fieldName: this.data.fieldName,
          value: this.input,
          errors: errors,
        });
      }, 0);
    },
  },
  props: {
    externalUpdatedValue: {
      required: false,
      type: String,
    },
  },
  watch: {
    externalUpdatedValue(value) {
      this.input = value;
    },
  },
};
